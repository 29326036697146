<template lang="pug">
  v-container
    v-row
      v-col.pb-0(cols="12")
        v-alert(
          class="mt-2"
          type="success"
          dismissible
          v-if="successMessage"
        )
          span.text-uppercase {{ successMessage }}

        v-alert(
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in errorMessages"
          :key="index"
        )
          span.text-uppercase {{ item.first() }}
      v-col.pt-0(cols="4")
        // <create-stock-feed-detail />
        router-view(
          @success="initSuccessMessage"
          @error="initErrorMessage"
          @reset="resetMessages"
          :details="stockFeedDetail"
        )
      v-col.pt-0(cols="8")
        table-stock-feed-detail(
          @edit-item="item => { stockFeedDetail = item }"
        )
</template>
<script>
export default {
  name: 'StockFeedDetail',
  components: {
    tableStockFeedDetail: () => import('./Table'),
  },
  data () {
    return {
      successMessage: null,
      stockFeedDetail: {},
      errorMessages: [],
    }
  },
  methods: {
    initSuccessMessage (message) {
      this.successMessage = message
    },
    initErrorMessage (message) {
      this.errorMessages = message
    },
    resetMessages (varName, val = null) {
      this[varName] = val
    },
  },
}
</script>
